<template>
  <div>
    <div :class="{ wiggle: showError, 'show-validation-error': showError }">
      <slot />
    </div>
    <div
      v-if="hasRules || hint"
      :class="{ 'align-left': alignLeft }"
      class="validator__msg"
    >
      <transition name="fade" mode="out-in">
        <span
          v-if="showError"
          :id="messageId"
          key="error"
          data-cy="validatorErrorMsg"
          class="validator__error"
        >
          {{ message }}
        </span>
        <span
          v-else-if="hint"
          :id="messageId"
          key="hint"
          data-cy="validatorHintMsg"
          class="validator__hint"
        >
          {{ hint }}
        </span>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputValidator',
  props: {
    rules: { type: Array, default: () => [] },
    value: { type: [Object, String, Boolean, Number], default: undefined },
    hint: { type: String, default: undefined },
    messageId: { type: String, default: undefined },
    alignLeft: { type: Boolean, default: false },
    showErrorOnValueChange: {
      type: Boolean,
      default: false
    }
  },
  emits: ['valid'],
  data() {
    return {
      valid: true,
      showError: false,
      message: undefined
    }
  },
  computed: {
    hasRules() {
      return this.rules.length > 0
    }
  },
  watch: {
    value() {
      this.validate(this.showErrorOnValueChange)
    }
  },
  methods: {
    validate(showError) {
      let internalValid = true

      if (this.rules && this.rules.length > 0) {
        const validationArray = this.rules.map(fkt =>
          typeof fkt === 'function' ? fkt(this.value) : fkt
        )

        for (const entry of validationArray) {
          if (entry !== true) {
            internalValid = false
            if (typeof entry === 'string') {
              this.message = entry
              break
            }
          }
        }
      }

      if (internalValid) {
        this.valid = true
        this.message = undefined
        this.showError = false
        this.$emit('valid', true)

        return true
      } else {
        this.valid = false
        this.showError = this.showError || showError
        this.$emit('valid', false)

        return false
      }
    },
    reset() {
      this.showError = false
      this.valid = true
      this.message = undefined
    }
  }
}
</script>

<style lang="scss">
@import 'src/components/common/stages/keyframes';
</style>

<style lang="scss" scoped>
.wiggle {
  animation: wiggle 0.6s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.validator__msg {
  display: flex;
  flex-direction: column;
  text-align: right;
  min-height: calc-rem(27px);
  width: 100%;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 8px;
  font-size: 0.8rem;
  line-height: 1.3;
}

.validator__error {
  color: var(--error-color);
}

.validator__hint {
  color: rgb(var(--root-color-rgb) / 70%);
}

.align-left {
  text-align: left;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.4s;
}

.fade-enter-from,
.fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}
</style>
